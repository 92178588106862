import React from 'react';
import {
    AboutSection,
    ContactSection,
    HeroSection,
    InterestsSection,
    Page,
    ProjectsSection,
    Seo,
} from 'gatsby-theme-portfolio-minimal';

export default function IndexPage() {
    return (
        <>
            <Seo title="John Doe" />
            <Page useSplashScreenAnimation>
                <HeroSection sectionId="hero" />
                <InterestsSection sectionId="skills" heading="Interests & Skills" />
                <AboutSection sectionId="about" heading="About me" />
                <ProjectsSection sectionId="projects" heading="Projects" />
                <ContactSection sectionId="contact" heading="Contact Me" />
            </Page>
        </>
    );
}
